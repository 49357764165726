<template>
  <div class="app" ref="header">
  <div class="query-container" :style="tableHeight">
    <el-row :md="24">

      <div class="bg">
        <h3>查詢顧客</h3>
        <el-input
          prefix-icon="el-icon-search"
          placeholder="試一試查詢：顧客手提號碼"
          v-model="queryDb.phone"
          class="query-customer-input">
          <el-select v-model="queryDb.teamType" slot="prepend" placeholder="選擇規則" style="margin-right: 12px">
              <el-option label="CallTeam" value="1"></el-option>
              <el-option label="RoadShow" value="2"></el-option>
          </el-option>
        </el-select>
          <el-button slot="append" type="primary" @click="queryBtn">查詢</el-button>
        </el-input>
      </div>

      <div class="card" v-if="data.errmsg">
        <i :class="{'fa fa-times no':data.errcode == 1,'fa fa-check-circle yes':data.errcode == 0}"></i>
        <p>{{data.errmsg}}</p>
      </div>


    </el-row>
  </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        queryDb:{
          phone:'',
          teamType:'',
        },
        screenHeight: document.body.clientHeight,
        tableHeight:{
          height:window.innerHeight-100 + "px"
        },
        data:{
          errmsg:'',
          errcode:1
        },
      }
    },
    mounted() {
      let that = this
      // 监听屏幕高度
      window.onresize = () => {
        return (() => {
          window.screenHeight = document.body.clientHeight;
          that.screenHeight = window.screenHeight;
        })();
      };
    },
    methods:{
      // 查询按钮
      queryBtn() {
        let that = this;
        let param = that.$atk.json_to_url({
          phone:that.queryDb.phone,
          teamType:that.queryDb.teamType
        });

        if(that.queryDb.phone == ""){
            that.$notify({
              title: "警告",
              message: "請填寫手提號碼。",
              type: "warning",
            });
            return
        }else if(that.queryDb.teamType <= 0){
            that.$notify({
              title: "警告",
              message: "請選擇規則。",
              type: "warning",
            });
            return
        }
        // 数字验证！
        var reg = new RegExp("^[0-9]*$");
        if(!reg.test(that.queryDb.phone)){
            that.$notify({
              title: "警告",
              message: "手提號碼必須為數字。",
              type: "warning",
            });
            return
        }
        that.$http.GET("/v1/booking/queryCustomer?"+param,(ret)=> {
              that.data = ret
          },(err)=> {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      }
    },
    watch: {
      // 监听屏幕高度改变表格高度
      screenHeight(){
        // 初始化表格高度
        this.tableHeight.height = window.innerHeight-100 + "px";
      }
    },
  }
</script>
<style scoped lang="scss">
.query-container{
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;
  .bg {
    margin-top: 3%;
    width: 500px;
    padding: 2rem 3rem;
    h3{
      font-size: 30px;
      text-align: center;
    }
    .el-input-group__prepend{
      .el-select{
          width: 130px;
      }
    }
    .query-customer-input {
      button {
        height: 100%;
        border-radius: 0px 3px 3px 0px;
        color: #fff;
        background-color: #25334e;
      }
      button:hover {
        background-color: #182132;
      }
    }
  }
  .card{
    width: 500px;
    padding: 2rem 3rem;
    background: #fff;
    //color: #fff;
    text-align: center;
    margin-bottom: 24px;
    color: #808695;
    font-size: 14px;
    line-height: 22px;
    border-radius: 3px;
    //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    p{
      font-size: 20px;
      font-weight: 900;
    }
    .yes{
      font-size: 70px;
      color: #67C23A;
    }
    .no{
      font-size: 70px;
      color: #F56C6C;
    }
  }
}
</style>
